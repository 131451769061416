<template >
  <div class="containter">
    <div class="mainListArea" id="mainListScroll">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <div class="intimeCarListArea">
          <van-list
            v-model="loading"
            :finished="finished"
            :error.sync="error"
            :offset="70"
            error-text="请求失败，点击重新加载"
            finished-text
            @load="onLoad"
          >
            <div class="listCell" v-for="item in list" :key="item.id">
              <div class="listItem" @click="gotoDetail(item)">
                <div class="listImgArea">
                  <div class="listImgAreaTrue">
                    <van-image
                      v-if="item.materialId"
                      fit="cover"
                      :src="item.materialId"
                      style="width:100%;height:100%;"
                    />
                  </div>
                </div>
                <div class="listContentArea">
                  <div class="height02rem"></div>

                  <div class="listContentAreaTitleLine">{{item.wishTitle}}</div>

                  <div class="listContentAreaFontLine">许愿人：{{item.wishPersonName}}</div>
                  <div
                    class="listContentAreaFontLine"
                  >截止日期：{{item.endTime?item.endTime.split(' ')[0]:''}}</div>
                  <!-- <div
                    v-if="item.wishStatus===1"
                    class="baomingButton backRed"
                    @click="getTaskPost(item)"
                  >待审核</div>-->
                  <div
                    v-if="item.wishStatus===2"
                    class="baomingButton backRed"
                    @click="getTaskPost(item)"
                  >待领取</div>
                  <!-- 未审核 -->
                  <img
                    v-if="item.wishStatus===1"
                    src="../../assets/img/notApproved.png"
                    class="approveImg"
                  />
                  <!-- 进行中 -->
                  <div v-if="item.wishStatus===3" class="baomingButton backWhite">已领取</div>
                  <!-- 已终止 -->
                  <div v-if="item.wishStatus===4" class="baomingButton backGrey">已完成</div>
                  <div v-if="item.wishStatus===5" class="baomingButton backGrey">已驳回</div>
                </div>
              </div>
            </div>
            <!-- <div class="no-data" v-if="loading">加载中...</div> -->
            <div class="no-data" v-if="list.length === 0 && !loading">暂无数据</div>
          </van-list>
        </div>
      </van-pull-refresh>

      <!-- <div class="wantofeedbackFontArea">扫描二维码许愿</div> -->
    </div>

    <img :src="sfScreen" class="sfScreen" />
    <img :src="sfScreenBottom" class="sfScreenBottom" />
    <div class="wantofeedbackArea">
      <div class="qrCodeArea">
        <img :src="code" style="width:100%;height:100%;" />
      </div>
    </div>
    <!-- <div class="wantofeedback" @click="gotoFeedback">
      <img :src="feedbackImg" style="width:100%;height:100%;" />
    </div>-->
  </div>
</template>

<script>
import {
  screenTinyWishList,
  screenTinyWishGetQrCode,
  getWishIntime
} from '@/api/bigScreen';
import { Notify } from 'vant';
export default {
  name: 'SFDashboardList',

  data() {
    return {
      // 任务的四种状态 1-待领取 2-进行中 3-已完成 4-已终止
      // 审核的两种状态 1-待审核 2-已审核
      feedbackImg: require('../situationFeedback/imgs/wantToFeedback.png'),
      sfScreen: require('../situationFeedbackScreen/imgs/smallWishScreen.png'),
      sfScreenBottom: require('../situationFeedbackScreen/imgs/smallWishBottom.png'),
      selectRow: 1,
      param: {
        page: 0,
        size: 5,
        total: 20
      },
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      fakeList: [],
      // 页面滑动的高度
      scrollTopValue: 0,
      list: [],
      // 二维码
      code: '',
      timerUpdata: {},
      checkTimer: {}
    };
  },
  watch: {},
  created() {
    this.getQrCode();
    this.getRecordsFirst();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  activated() {
    // console.log(this.scrollTopValue);
    // const mainListScroll = document.getElementById('mainListScroll');
    // if (this.scrollTopValue > 0) {
    //   mainListScroll.scrollTop = this.scrollTopValue;
    // }
  },
  beforeRouteLeave(to, from, next) {
    // if (to.name == 'hadGetDetailScreen') {
    //   this.$route.meta.keepAlive = true;
    //   const mainListScroll = document.getElementById('mainListScroll')
    //     .scrollTop;
    //   this.scrollTopValue = mainListScroll;
    //   console.log(this.scrollTopValue);
    // } else {
    //   this.$route.meta.keepAlive = false;
    // }
    // 清除计时器
    window.clearInterval(this.timerUpdata);
    window.clearInterval(this.checkTimer);
    next();
  },
  mounted() {
    const this_ = this;
    this.$nextTick(function() {
      this_.checkTimer = setInterval(function() {
        this_.checkWishIntime();
      }, 1000);
    });
  },
  methods: {
    // 动态更新动画
    anotationUpdate(y, time) {
      // ocument.getElementsByClassName('intimeCarListArea').scrollTop = -100;
      var style = document.createElement('style');
      style.type = 'text/css';
      var keyFrames = `@keyframes intimeCarListPlay {from {-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);}to {-webkit-transform: translate3d(0, -${y}px, 0);transform: translate3d(0, -${y}px, 0);}} `;
      keyFrames += ` .intimeCarListArea {
  animation: intimeCarListPlay ${time}s linear infinite;
}`;
      style.innerHTML = keyFrames;
      document.getElementsByTagName('head')[0].appendChild(style);
    },
    // 获取许愿页面二维码
    getQrCode() {
      screenTinyWishGetQrCode({}).then(res => {
        console.log(res);
        const data = res.data;
        this.code = data;
      });
    },
    // 获取是否有新的心愿
    checkWishIntime() {
      const this_ = this;
      getWishIntime({}).then(res => {
        console.log(res);
        const data = res.data;
        if (data) {
          this.$router.go(0);
          this_.list = [];
          this_.getRecords();
          this_.getRecordsFirst();
        }
      });
    },

    getList() {
      //请求列表接口
      this.loading = true;
      screenTinyWishList({}).then(res => {
        console.log(res);
        this.list = res.data;

        this.loading = false;
        this.refreshing = false;
      });
    },
    //加载更多数据
    onLoad() {
      console.log('加载自动刷新');
      const timer = setTimeout(() => {
        this.getRecords();
        this.loading = false;
        clearTimeout(timer);
      }, 500);
    },
    getRecords() {
      const this_ = this;
      const param = this.param;
      param.page += 1;
      const searchParam = { ...param };
      searchParam.status = this.selectRow;
      // 调接口
      screenTinyWishList({ current: searchParam.page, size: 1000 })
        .then(res => {
          console.log('成功');
          const activityList = res.data.records;
          // 数据全部加载完成
          if (activityList.length === 0) {
            this_.finished = true;
          }
          let list = this_.list;
          list = [...list, ...activityList];
          this_.list = JSON.parse(JSON.stringify(list));
          // this_.list = activityList;
          console.log(this_.list);
          param.total = res.data.total;
          this_.param = param;
          // 加载状态结束
          this_.loading = false;
          this_.refreshing = false;
          this_.error = false;
        })
        .catch(err => {
          // 加载状态结束
          console.log('失败');
          this_.error = true;
          console.log(err);
        });
    },
    // 设置时间和高度
    getRecordsFirst() {
      const this_ = this;
      // 调接口
      screenTinyWishList({
        current: 1,
        size: 1000
      })
        .then(res => {
          if (res.data.records && res.data.records.length > 0) {
            const total = res.data.total;
            const height = 350 * (total - 1);
            const second = total * 4 + 1;
            // this_.$nextTick(function() {
            //   this_.anotationUpdate(height, second);
            // });
            this_.anotationUpdate(height, second);
            window.clearInterval(this_.timerUpdata);
            this_.timerUpdata = setInterval(function() {
              this_.getRecordsFirst();
              this_.param.page = 0;
              this_.list = [];
              this_.getRecords();
            }, 3600 * 1000);
          }
        })
        .catch(err => {
          // 加载状态结束
          console.log('失败');
          this_.error = true;
          console.log(err);
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.list = [];
      this.param.page = 0;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      console.log('下拉刷新');
      this.onLoad();
    },
    // 查询的改变
    changeList(num) {
      this.selectRow = num;
      this.param.page = 0;
      this.finished = false;
      this.list = [];
      this.getRecords();
    },
    gotoFeedback() {
      this.$router.push({
        name: 'sfDashboardIWantTo'
      });
    },
    gotoDetail(row) {
      this.$router.push({
        name: 'hadGetDetailScreen',
        query: { id: row.id }
      });
    },
    getInputChange() {
      this.searchIconVisible = false;
    },
    blurChange() {
      this.searchIconVisible = true;
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
/deep/ .van-icon {
  display: flex;
  align-items: center;
}
/deep/ .van-cell {
  padding: 0px;
}
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.headSearchLine {
  width: 100%;
  height: 40 * $px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.searchInput {
  width: 355 * $px;
  height: 29 * $px;
  line-height: 29 * $px;
  border-radius: 14.5 * $px;
  background-color: #f5f5f5;
  padding: 0px;
}
.searchIcon {
  width: 12 * $px;
  height: 12 * $px;
  position: absolute;
  top: 13.5 * $px;
  left: 146 * $px;
  z-index: 1;
}
.radioButtonLine {
  height: 34 * $px;
  width: 100%;
  background-color: #ffffff;
}
.radioButtonItem {
  width: 33.33%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.radioButtonFont {
  font-size: 14 * $px;
  color: #555555;
  margin-bottom: 7 * $px;
}
.radioButtonBottomLine {
  width: 30 * $px;
  height: 2 * $px;
  border-radius: 0.5 * $px;
  background-color: #ffffff;
}
.listCell {
  margin: 10 * $px 31 * $px 0 31 * $px;
  width: 313 * $px;
  background-color: #ffffff;
  border-radius: 8 * $px;
  box-shadow: 0px 2 * $px 12 * $px 0px rgba(0, 0, 0, 0.14);
}
.listItem {
  width: 100%;
  overflow: auto;
  position: relative;
}

.commentsAvtorArea {
  width: 45 * $px;
  float: left;
  display: flex;
  justify-content: center;
}
.commentsAvtor {
  width: 33 * $px;
  height: 33 * $px;
  background-color: grey;
  overflow: hidden;
  border-radius: 16.5 * $px;
  margin-left: 12 * $px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.commentsContentArea {
  margin-left: 8 * $px;
  width: 298 * $px;
  float: left;
}
.commetUserAndDate {
  height: 15 * $px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontName {
  color: #555555;
  font-size: 14 * $px;
}
.fontDate {
  color: #999999;
  font-size: 11 * $px;
  margin-right: 10 * $px;
}
.fontContent {
  margin: 8 * $px 0 0 0;
  color: #222222;
  font-size: 14 * $px;
}
.commentsBottomLine {
  width: 100%;
  height: 0.5 * $px;
  background-color: #eeeeee;
  margin-top: 15 * $px;
}
.commentsImgLine {
  width: 100%;
  word-break: break-all;
  overflow: auto;
}
.commentsImgItem {
  height: 70 * $px;
  width: 70 * $px;
  margin-top: 9 * $px;
  margin-right: 10 * $px;
  background-color: #dddddd;
  overflow: hidden;
  float: left;
}
.height15 {
  width: 100%;
  height: 15 * $px;
}
.height12 {
  width: 100%;
  height: 12 * $px;
}
.dateLine {
  width: 100%;
  height: 13 * $px;
  font-size: 12 * $px;
  color: #999999;
  margin-top: 10 * $px;
}
.baseLine {
  width: 331 * $px;
  height: 0 * $px;
  border-bottom: #eeeeee 0.6 * $px solid;
  margin-left: 12 * $px;
  float: left;
}
.bottomLine {
  width: 100%;
  height: 35 * $px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.greyFont {
  font-size: 12 * $px;
  color: #999999;
  margin-right: 10 * $px;
}
.redFont {
  margin-right: 10 * $px;
  font-size: 12 * $px;
  color: #ff192f;
}
.greyFontLeft {
  font-size: 12 * $px;
  color: #222222;
  margin-left: 10 * $px;
}
.wantofeedback {
  width: 71.5 * $px;
  height: 71.5 * $px;
  position: fixed;
  right: 14 * $px;
  bottom: 14 * $px;
}
.no-data {
  padding: 40 * $px;
  text-align: center;
  font-size: 20 * $px;
}
.wantofeedbackArea {
  width: 2rem;
  height: 2rem;
  position: fixed;
  right: 0.76rem;
  bottom: 1.07rem;
  z-index: 100;
}
.wantofeedbackFontArea {
  width: 2rem;
  height: 0.4rem;
  position: fixed;
  right: 0.76rem;
  bottom: 0.67rem;
  z-index: 100;
  font-size: 8 * $px;
  color: #ffffff;
  text-align: center;
}
.qrCodeArea {
  width: 100%;
  height: 100%;
  background-color: #999999;
}
.qrCodeFont {
  width: 100%;
  height: 35 * $px;
  line-height: 35 * $px;
  text-align: center;
  font-size: 18 * $px;
  color: #555555;
}
.sfScreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
}
.height2rem {
  height: 1.5rem;
  width: 100%;
}
.listImgArea {
  height: 3rem;
  width: 3rem;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listImgAreaTrue {
  background-color: rgb(224, 224, 224);
  width: 2.5rem;
  height: 2.5rem;
}
.listContentArea {
  width: 190 * $px;
  height: 3rem;
  float: left;
  position: relative;
  z-index: 10;
}
.height02rem {
  height: 0.2rem;
  width: 100%;
}
.listContentAreaTitleLine {
  height: 0.7rem;
  width: 100%;
  line-height: 0.7rem;
  font-size: 16 * $px;
  color: #222222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.listContentAreaFontLine {
  height: 0.55rem;
  width: 100%;
  line-height: 0.55rem;
  font-size: 12 * $px;
  color: #555555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainListArea {
  width: 100%;
  position: fixed;
  top: 1.4rem;
  left: 0rem;
  right: 0rem;
  bottom: 3.5rem;
  overflow: hidden;
  overflow-y: auto;
  z-index: 10;
}
.baomingButton {
  width: 60 * $px;
  height: 20 * $px;
  line-height: 20 * $px;
  text-align: center;
  position: absolute;
  bottom: 10 * $px;
  right: 0 * $px;
  border-radius: 12 * $px;
  border: #ff192f 1 * $px solid;
  // box-sizing: border-box;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  font-size: 12 * $px;
  margin-right: 10 * $px;
  margin-left: 10 * $px;
}
.backRed {
  background-color: #ff192f;
  color: #ffffff;
}
.backWhite {
  background-color: #ffffff;
  color: #ff192f;
}
.backGrey {
  background-color: #ffffff;
  color: #999999;
  border: #999999 1 * $px solid;
}
.approveImg {
  width: 52 * $px;
  height: 44 * $px;
  position: absolute;
  right: 0 * $px;
  top: 15 * $px;
  z-index: 0;
}
.sfScreenBottom {
  width: 100%;
  height: 5.646667rem;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10;
}
</style>



